<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">DPS: {{ item.name + " " + item.lastName }}</span>
      </v-card-title>
      <v-card-subtitle>
        <span class="attr">Data de nascimento: {{ formatDate(item.birthDate) }}</span>
        <span class="attr">Sexo: {{ item.sex }}</span>
        <span class="attr">Altura: {{ height }}</span>
        <span class="attr">Peso: {{ weight }}</span>
        <span class="attr">IMC: {{ imc }}</span>
      </v-card-subtitle>
      <v-card-text class="items">
        <div v-if="!error">
          <div v-for="(q, index) in form" :key="index">
            <div
              v-if="!(q.reference === 'beneficiary-weight' || q.reference === 'beneficiary-height')"
            >
              <p>
                <strong>{{ q.question }}</strong>
              </p>
              <p>{{ q.answer }}</p>
            </div>
          </div>
        </div>
        <div v-else class="error-dps">
          <h2>Puxa, não foi possível recuperar a DPS!</h2>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close()">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { get } from "@/utils/api";
import { mask } from "@/utils/mask";

export default {
  name: "ViewDps",
  props: ["item", "toggle"],
  data() {
    return {
      form: [],
      error: false
    };
  },
  mounted() {
    this.getDps(this.item);
  },
  watch: {
    item(item) {
      this.form = [];
      this.getDps(item);
    }
  },
  methods: {
    close() {
      if (this.toggle) this.toggle();
    },
    formatDate(date) {
      return new Intl.DateTimeFormat("pt-BR", { timeZone: 'UTC' }).format(new Date(date));
    },
    calculateIMC({ height, weight }) {
      const _weight = parseFloat(weight);
      const _height = parseFloat(`${height}`.replace(/\D/g, "")) / 100;
      const bmi = _weight / (_height * _height);
      return bmi;
    },
    async getDps() {
      if (this.error) this.error = !this.error;
      const key = this.item.documentKey ? this.item.documentKey : this.item.id;
      const path = `/dps/questions/${key}`;
      try {
        this.$store.commit("loading", true);
        const { answers } = await get({ path });
        this.form = answers;
      } catch (error) {
        console.error(error);
        this.error = true;
      } finally {
        this.$store.commit("loading", false);
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    imc() {
      if (this.height && this.weight) {
        return this.calculateIMC({ height: this.height, weight: this.weight }).toFixed(2);
      }
    },
    height() {
      if (this.form.length > 0) {
        const height = this.form.find(d => d.reference === "beneficiary-height");
        if (height) return mask(height.answer.toString(), "### cm");
      }
      return "";
    },
    weight() {
      if (this.form.length > 0) {
        const weight = this.form.find(d => d.reference === "beneficiary-weight");
        if (weight) return mask(weight.answer.toString(), "### Kg");
      }
      return "";
    }
  }
};
</script>
<style scoped>
.attr {
  margin: 0 10px 0 0;
  white-space: nowrap;
}
.items {
  overflow: auto;
  height: 70vh;
}
.error-dps {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
